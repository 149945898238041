<template>
	<div>
		<div
			class="BpForms BpInput"
			:class="stateComputed.currentClasses"
			:data-testid="id + '-input'">
			<label
				v-if="state.label"
				class="BpForms--label"
				:for="state.id">
				{{ state.label }}
				<span
					v-if="state.tooltip"
					class="Tooltip Tooltip-bottom"
					:data-tooltip="state.tooltip"
					tabindex="0"
					data-testid="blueprint-input-tooltip"
					><span class="Tooltip-icon BpForms--tooltip"></span
				></span>
				<span
					class="BpForms--optional"
					data-testid="forms-optional-label">
					{{ language.string.cForms.optional }}
				</span>
			</label>
			<input
				:id="state.id"
				ref="inputRef"
				v-model="state.value"
				class="BpForms--input BpInput--input"
				:class="`Text-${state.textAlign}`"
				:name="state.id"
				:type="internal.typeOverwrite ? internal.typeOverwrite : state.type"
				:placeholder="state.placeholder"
				:required="state.required"
				:disabled="state.disabled"
				:step="state.increment"
				:aria-required="state.required"
				:maxlength="state.maxLength"
				:max="stateComputed.max"
				:min="stateComputed.min"
				:list="stateComputed.listId"
				:passwordrules="internal.passwordrules"
				data-testid="blueprint-input-input"
				@blur="onBlur"
				@focus="onFocus"
				@input="updateValue(true)"
				@change="updateValue()" />
			<div
				v-if="state.icon"
				:class="state.label ? state.icon : state.icon + ' BpForms--icon-noLabel'"
				class="BpForms--icon"
				data-testid="blueprint-input-icon"></div>
			<div
				v-if="revealPass"
				class="BpInput--eye iconFont-eye"
				:class="internal.typeOverwrite && 'is-active'"
				data-testid="blueprint-input-password-reveal"
				@click="internal.typeOverwrite = internal.typeOverwrite ? null : 'input'"></div>

			<datalist
				v-if="state.autocomplete"
				:id="stateComputed.listId"
				data-testid="blueprint-input-datalist">
				<option
					v-for="item in state.autocomplete"
					:key="item.message"
					:value="item"
					data-testid="blueprint-input-datalist-option"></option>
			</datalist>

			<transition name="slide-down">
				<div
					v-if="internal.currentMsg"
					class="BpForms--msg"
					data-testid="blueprint-input-msg"
					:data-msgtype="internal.currentMsgType">
					{{ internal.currentMsg }}
				</div>
			</transition>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';
	import * as Helpers from './helpers.js';

	export default {
		name: 'BlueprintInput',

		// ---------- PROPS ----------
		props: {
			/**
			 * property {string} id - unique id
			 * @namespace Core_Blueprint_Input
			 * @property {string} id - unique id
			 */
			id: {
				type: String,
				required: true
			},

			/**
			 * property {string | number | null | undefined} [value] - bind value
			 * @namespace Core_Blueprint_Input
			 * @property {string | number | null | undefined} [value] - bind value
			 */
			value: {
				type: [String, Number, null, undefined],
				required: false,
				default: undefined
			},

			/**
			 * property {string} [label] - label, or title
			 * @namespace Core_Blueprint_Input
			 * @property {string} [label] - label, or title
			 */
			label: {
				type: String,
				required: false,
				default: undefined
			},

			/**
			 * property {string} [type] - define type of input (one of: 'number', 'date', 'email', 'tel', 'password', 'website', 'name')
			 * @namespace Core_Blueprint_Input
			 * @property {string} [type] - define type of input (one of: 'number', 'date', 'email', 'tel', 'password', 'website', 'name')
			 */
			type: {
				type: String,
				required: false,
				validator: function (value) {
					return [
						'number',
						'date',
						'email',
						'tel',
						'password',
						'website',
						'name',
						'telCountry'
					].includes(value);
				},
				default: undefined
			},

			/**
			 * property {boolean} [revealPass] - allow to show pass (only with password type field)
			 * @namespace Core_Blueprint_Input
			 * @property {boolean} [revealPass=false] - allow to show pass (only with password type field)
			 */
			revealPass: {
				type: Boolean,
				required: false,
				default: false
			},

			/**
			 * property {string} [placeholder] - placeholder text
			 * @namespace Core_Blueprint_Input
			 * @property {string} [placeholder] - placeholder text
			 */
			placeholder: {
				type: String,
				required: false,
				default: undefined
			},

			/**
			 * property {boolean} [disabled] - should this field be "disabled"
			 * @namespace Core_Blueprint_Input
			 * @property {boolean} [disabled] - should this field be "disabled"
			 */
			disabled: {
				type: Boolean,
				required: false
			},

			/**
			 * property {boolean} [required=false] - is this field required?
			 * @namespace Core_Blueprint_Input
			 * @property {boolean} [required=false] - is this field required?
			 */
			required: {
				type: Boolean,
				required: false
			},

			/**
			 * property {string} [icon] - url to icon or icon class name
			 * @namespace Core_Blueprint_Input
			 * @property {string} [icon] - url to icon or icon class name
			 */
			icon: {
				type: String,
				required: false,
				default: undefined
			},

			/**
			 * property {string} [msg] - message to display below the field
			 * @namespace Core_Blueprint_Input
			 * @property {string} [msg] - message to display below the field
			 */
			msg: {
				type: String,
				required: false,
				default: undefined
			},

			/**
			 * property {string} [msgType=notification] - type of the message (one of: 'notification', 'warning', 'success', 'error')
			 * @namespace Core_Blueprint_Input
			 * @property {string} [msgType=notification] - type of the message (one of: 'notification', 'warning', 'success', 'error')
			 */
			msgType: {
				type: String,
				required: false,
				validator: function (value) {
					return ['notification', 'warning', 'success', 'error'].includes(value);
				},
				default: 'notification'
			},

			/**
			 * property {string} [validation] - validation regex to follow
			 * @namespace Core_Blueprint_Input
			 * @property {string} [validation] - validation regex to follow
			 */
			validation: {
				type: String,
				required: false,
				default: undefined
			},

			/**
			 * property {number} [maxLength] - max number of characters
			 * @namespace Core_Blueprint_Input
			 * @property {number} [maxLength] - max number of characters
			 */
			maxLength: {
				type: Number,
				required: false,
				default: undefined
			},

			/**
			 * property {object} [autocomplete] - array of values that will be used to select "Autocomplete" functionality (datalist)
			 * @namespace Core_Blueprint_Input
			 * @property {object} [autocomplete] - array of values that will be used to select "Autocomplete" functionality (datalist)
			 */
			autocomplete: {
				type: Object,
				required: false,
				default: undefined
			},

			/**
			 * property {number | string} [max] - max to set for NUMBER or DATE type of input only. Can be used as string "today" for DATE TYPE
			 * @namespace Core_Blueprint_Input
			 * @property {number | string} [max] - max to set for NUMBER or DATE type of input only. Can be used as string "today" for DATE TYPE
			 */
			max: {
				type: [Number, String],
				required: false,
				default: undefined
			},

			/**
			 * property {number | string} [min=0] - min to set for NUMBER or DATE type of input only. Can be used as string "today" for DATE TYPE
			 * @namespace Core_Blueprint_Input
			 * @property {number | string} [min=0] - min to set for NUMBER or DATE type of input only. Can be used as string "today" for DATE TYPE
			 */
			min: {
				type: [Number, String],
				required: false,
				default: 0
			},
			/**
			 * property {string} [increment=1] - Set the increment step on input of type number
			 * @namespace Core_Blueprint_Input
			 * @property {string} [increment=1] - Set the increment step on input of type number
			 */
			increment: {
				type: Number,
				required: false,
				default: 1
			},
			/**
			 * property {string} [tooltip] - content to be shown as tooltip within label area
			 * @namespace Core_Blueprint_Input
			 * @property {string} [tooltip] - content to be shown as tooltip within label area
			 */
			tooltip: {
				type: String,
				required: false,
				default: undefined
			},

			/**
			 * property {Function} [onFocus=noop] - function to be called when field is focused
			 * @namespace Core_Blueprint_Input
			 * @property {Function} [onFocus=noop] - function to be called when field is focused
			 */
			onFocus: {
				type: Function,
				required: false,
				default: function () {}
			},
			/**
			 * property {Function} [onBlur=noop] - function to be called when blur event happens
			 * @namespace Core_Blueprint_Input
			 * @property {Function} [onBlur=noop] - function to be called when blur event happens
			 */
			onBlur: {
				type: Function,
				required: false,
				default: function () {}
			},

			/**
			 * property {string} [textAlign=left] - text alignment for input values
			 * @namespace Core_Blueprint_Input
			 * @property {string} [textAlign=left] - text alignment for input values
			 */
			textAlign: {
				type: String,
				required: false,
				default: 'left'
			},
			/**
			 * property {boolean} [showValidationText=true] - do we want validation text to be shown
			 * @namespace Core_Blueprint_Input
			 * @property {boolean} [showValidationText=true] - do we want validation text to be shown
			 */
			showValidationText: {
				type: Boolean,
				required: false,
				default: true
			},
			/**
			 * property {number} [debounceUpdateValue=0] - should we debounce update value
			 * @namespace Core_Blueprint_Input
			 * @property {number} [debounceUpdateValue=0] - should we debounce update value
			 */
			debounceValueMs: {
				type: Number,
				required: false,
				default: 0
			}
		},

		//  ---------- EMITS ----------
		emits: ['update:value'],

		//  ---------- SETUP ----------
		setup(props, context) {
			const language = useLanguageStore();
			const inputRef = Core.Vue.ref(null);
			// reactive props, that's all
			const state = Core.Vue.computed(() => {
				return {
					...props, // make all props reactive
					value:
						props.type === 'date' && typeof props.value === 'string'
							? props.value.substring(0, 10)
							: props.value,
					tooltip:
						// if password type, force tooltip (it explains password's complexity)
						props.type === 'password'
							? language.string.cForms.passwordHint
							: props.tooltip,
					maxLength: props.type === 'tel' ? 15 : props.maxLength // if tel type, it should have default maxLength set
				};
			});

			// internal values only (can be set from inside or outside)
			const internal = Core.Vue.reactive({
				currentMsg: state.value.msg,
				currentMsgType: state.value.msgType,
				passwordrules:
					props.type === 'password'
						? 'minlength: 8; required: lower; required: upper; required: digit; required: [!@#$%^&*];'
						: null,
				typeOverwrite: null
			});

			// our computed and transformed values to use as we need (from props)
			const stateComputed = Core.Vue.computed(() => {
				return {
					min:
						state.value.min && state.value.min === 'today'
							? Core.Utils.createDate()
							: state.value.min,
					max:
						state.value.max && state.value.max === 'today'
							? Core.Utils.createDate()
							: state.value.max,
					listId: state.value.autocomplete ? `list${state.value.id}` : null,
					currentClasses: Helpers.resolveClassNames(state.value, internal)
				};
			});

			// allow update to internals from outside via props
			Core.Vue.watch(
				() => [props.msg, props.msgType],
				([msgNew, msgTypeNew]) => {
					internal.currentMsg = msgNew;
					internal.currentMsgType = msgTypeNew;
				}
			);

			/* -------------------- UPDATE & VALIDATE FUNCTIONALITY ---------------------- */
			let debounce = null; // to handle debounce
			let debounceValue = null; // to handle debounce on update value
			/**
			 * updateValue the value from input, validate if needed
			 * @param {boolean} [delayed=false] if we need to debounce, set to true
			 * @returns {boolean} false if validation failed
			 */
			function updateValue(delayed = false) {
				// If delayed set, call this function in 300ms just without delay to execute the second part of the code

				if (debounce) clearTimeout(debounce);
				if (debounceValue) clearTimeout(debounceValue);

				if (delayed) {
					debounce = setTimeout(() => {
						validate();
					}, 750);
				} else {
					validate();
				}

				// executed only if called without the "delayed"

				// if we always trim, it will cast numbers into string (stupid, right?)
				if (typeof state.value.value === 'string') {
					state.value.value = state.value.value.trim();
				}

				//@todo @DamjanESG the default debounce value should be 500-700 and only in specific situations, we should allow to change to something else. Default should be debounced
				if (state.value.debounceValueMs > 0) {
					debounceValue = setTimeout(() => {
						context.emit('update:value', state.value.value);
					}, state.value.debounceValueMs);
				} else {
					context.emit('update:value', state.value.value);
				}

				return true;
			}

			/**
			 * validate the field
			 * @returns {boolean} validation result
			 */
			function validate() {
				return Helpers.validators(state, internal, inputRef);
			}

			/**
			 * Reset field to initial state
			 *
			 */
			function reset() {
				Helpers.resetAll(internal, state);

				//Let the component where its used to be aware of the reset value
				context.emit('update:value', state.value.value);
			}

			return {
				state,
				stateComputed,
				internal,
				language,
				inputRef,
				updateValue,
				validate,
				reset
			};
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@import './index.scss';
	@include block('BpInput') {
		@include modifier('passReveal') {
			& .BpInput--input {
				padding-right: 45px;
			}

			// fix to revealPass if grid column was set on the component itself
			&[class*='col-'] .BpInput--eye {
				right: var(--space-double) !important;
			}
		}

		@include element('eye') {
			height: 20px;
			font-size: 20px;
			bottom: var(--space-double);
			position: absolute;
			width: 20px;
			right: var(--space-single);
			color: var(--color-grey400);

			&:hover {
				cursor: pointer;
				color: var(--color-stateDanger);
			}

			&.is-active {
				color: var(--color-stateSuccess);
			}
		}
	}
</style>
