/**
 * @module Student/RouterPermissions
 */

import * as Store from '@Student/store/index.js';
import CONFIG from '@Student/config.js';

// permission mapping
const PREDEFINED_PERMISSIONS = {
	loggedIn: loggedInPermission,
	loggedOut: loggedOutPermission
};

/* ---------------------------------- PRIVATE METHODS ------------------------------------------ */
/**
 * If the user is logged in, then we load the current page. If not, we load the home page
 * @param {string} path to selecter router
 * @returns {string} The response is a string that tells the router what to do next.
 */
function loggedInPermission(path) {
	if (Store.user.getUser.loggedIn) {
		return path;
	} else {
		return CONFIG.ROUTER_PERMISSION_REDIRECTS.home;
	}
}

/**
 * Views under this permission require to never be accessible if user is logged in.
 * If the user is logged in, follow the flow manager.
 * If the user is not logged in, load the curren page.
 * @param {string} path target path
 * @returns {string} The response is a string that tells the router what to do next.
 */
function loggedOutPermission(path) {
	if (Store.user.getUser.loggedIn) {
		return CONFIG.ROUTER_PERMISSION_REDIRECTS.home;
	} else {
		return path;
	}
}

/* ---------------------------------- PUBLIC METHODS ------------------------------------------ */
/**
 * check current path against permissions to figure out if they are allowed to see the page
 * @exports Student/RouterPermissions
 * @param {object} path object from vue router
 * @returns {string} Final path name to be resolved by the router
 */
export default function (path) {
	if (!path.meta.permissions) return path.path; // if permissions are empty

	let finalPathName = path.path;
	const permissions = path.meta.permissions.split(',');

	for (const item in permissions) {
		const newPath = PREDEFINED_PERMISSIONS[permissions[item]](finalPathName);

		// making sure we don't check any further permission if an early one failed
		if (finalPathName !== newPath) {
			finalPathName = newPath;
			break;
		}
	}

	return finalPathName;
}
