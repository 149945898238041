/**
 * THIS IS A TEMPLATE FILE FOR THE BUILD PROCESS, NOT THE FINAL FILE TO BE USED WITHE THE CODE!
 * The below "marker" will be filled out every time build process runs
 * It is like a pre-processor which will find all *.route files and create dynamic router configuration
 */

import * as Core from '@Core/index.js'

const routes = [
{
path: '/:pathMatch(.*)*',
	component: () => import('../views/404/index.vue'),
	meta: {
		design: 'full',
		title: {
			en: '404 Not Found',
			pl: 'Błąd 404'
		}
	}
},
{
	path: '/article',
	components: {
		default:  () => import('../views/Article/index.vue')
	},
	meta: {
		title: {
			en: 'Articles',
			pl: 'Artykuły'
		}
	}
},
{
	path: '/article/:productSlug',
	components: {
		default:  () => import('../views/Article/singleArticle.vue')
	},
	meta: {
		title: {
			en: '%productTitle%'
		}
	}
},
{
	path: '/course',
	components: {
		default:  () => import('../views/Course/index.vue')
	},
	meta: {
		title: {
			en: 'Courses',
			pl: 'Kursy'
		}
	}
},
{
	path: '/course/:productSlug',
	components: {
		default:  () => import('../views/Course/singleCourse.vue')
	},
	meta: {
		title: {
			en: '%productTitle%',
			pl: '%productTitle%'
		}
	}
},
{
	path: '/course/:productSlug/:lessonId',
	components: {
		default:  () => import('../views/Course/lesson.vue')
	},
	meta: {
		title: {
			en: '%lessonTitle%',
			pl: '%lessonTitle%'
		},
		permissions: "loggedIn"
	}
},
{
	path: '/course/:productSlug/:lessonId/:assignmentId',
	components: {
		default:  () => import('../views/Course/assignment.vue')
	},
	meta: {
		title: {
			en: '%assignmentTitle%',
			pl: '%assignmentTitle%'
		},
		permissions: "loggedIn"
	}
},
{
	path: '/download',
	components: {
		default:  () => import('../views/Download/index.vue')
	},
	meta: {
		title: {
			en: 'Digital Downloads',
			pl: 'Produkty do pobrania'
		}
	}
},
{
	path: '/download/:productSlug',
	components: {
		default:  () => import('../views/Download/singleDownload.vue')
	},
	meta: {
		title: {
			en: '%productTitle%'
		}
	}
},
{
path: '/error',
	component: () => import('../views/error/index.vue'),
	meta: {
		design: 'full',
		title: {
			en: 'Error',
			pl: 'Błąd'
		}
	}
},
{
	path: '/',
	components: {
		default:  () => import('../views/Home/index.vue')
	},
	meta: {
		title: {
			en: 'Home'
		}
	}
},

{
	path: '/login/reset-password',
	components: {
		default:  () => import('../views/Login/resetPassword.vue')
	},
	meta: {
		design: 'full',
		permissions: "loggedOut",
		hideHeader: true,
		title: {
			en: 'Reset Password',
			pl: 'Reset hasła'
		}
	}
},
{
	path: '/login/activate-user',
	components: {
		default:  () => import('../views/Login/activateUser.vue')
	},
	meta: {
		design: 'full',
		permissions: "loggedOut",
		hideHeader: true,
		title: {
			en: 'Activate User',
			pl: 'Aktywacja konta'
		}
	}
},
{
	path: '/page',
	redirect: '/',
	components: {
		default:  () => import('../views/Page/index.vue')
	},
	meta: {
		title: {
			en: 'Page',
			pl: 'Strona'
		}
	}
},
{
	path: '/page/:slug',
	components: {
		default:  () => import('../views/Page/index.vue')
	},
	meta: {
		title: {
			en: '%pageTitle%'
		}
	}
},
{
	path: '/user',
	components: {
		default:  () => import('../views/User/index.vue')
	},
	meta: {
		title: {
			en: 'User Profile',
			pl: 'Mój profil'
		},
		permissions: "loggedIn"
	}
},
{
	path: '/user/products',
	components: {
		default:  () => import('../views/User/products.vue')
	},
	meta: {
		title: {
			en: 'My products',
			pl: 'Moje produkty'
		},
		permissions: "loggedIn"
	}
},
];

const router = Core.VueRouter.createRouter({
	history: Core.VueRouter.createWebHistory(),
	routes,
	scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    } else {
			return { top: 0 }
		}
  }
})

export default router