<template>
	<div class="row Breadcrumbs Space-bottomDouble">
		<div class="col-24">
			<h1 class="Text-h1 Breadcrumbs--heading">
				<span>{{ state.title || '&nbsp;' }}</span>
			</h1>
			<ul
				v-if="breadcrumbs"
				class="Space-leftNone">
				<li
					v-for="(crumb, index) in state.breadcrumbs"
					:key="index"
					class="d-inline">
					<router-link
						v-if="index < state.breadcrumbs.length - 1"
						class="Text-link Text-small"
						:to="crumb.link">
						{{ crumb.title }}
					</router-link>
					<span
						v-else
						class="Text-small">
						{{ crumb.title }}
					</span>

					<span v-if="index < state.breadcrumbs.length - 1"> &gt; </span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import * as Core from '@Core/index.js';

	export default {
		// ---------- PROPS ----------
		props: {
			/**
			 * PROP: breadcrumbs enabled or disabled flag
			 * @namespace Core_Breadcrumbs
			 * @property {boolean} [breadcrumbs=true] breadcrumbs enabled or disabled flag
			 */
			breadcrumbs: {
				required: false,
				type: Boolean,
				default: true
			},

			/**
			 * PROP: firstRoute - which is the first route of the app, the HOMEPAGE -> e.x. client-dashboard, devplatform - home
			 * @namespace Core_Breadcrumbs
			 * @property {string } [firstRoute='dashboard'] which is the first route of the app, the HOMEPAGE -> e.x. client-dashboard, devplatform - home
			 */
			firstRoute: {
				required: false,
				type: String,
				default: ''
			},

			/**
			 * PROP: crumbNameReplace - which is the name of the crumb to replace in the title
			 * @namespace Core_Breadcrumbs
			 * @property {object} storeData which is the name of the crumb to replace in the title
			 */
			storeData: {
				required: true,
				type: Object
			}
		},

		//  ---------- SETUP ----------
		setup(props) {
			const route = Core.VueRouter.useRoute();
			const router = Core.VueRouter.useRouter();

			const state = Core.Vue.reactive({
				title1: null,
				title2: null,
				breadcrumbs: []
			});

			// Initialise data for this component
			Core.Vue.onMounted(() => {
				if (props.breadcrumbs) {
					state.title = Core.Utils.pageTitle(route.meta, route, props.storeData);
					state.breadcrumbs = buildBreadcrumbs(router.getRoutes(), route.path);
				}
			});

			// --------- PRIVATE METHODS --------------------
			/**
			 * Recursive method to go through all paths in the url and figure out 'natural' breadcrumbs based on router configuration
			 * @namespace Core_Breadcrumbs
			 * @private
			 * @param {Array} routes from the vue router
			 * @param {Array | string} startPaths current path or exploded path to array
			 * @param {Array} [existingPaths=[]] array with all crumbs
			 * @returns {Array} of final crumbs
			 */
			function buildBreadcrumbs(routes, startPaths, existingPaths = []) {
				const paths = Array.isArray(startPaths) ? startPaths : startPaths.split('/');
				let pathToCheck = paths.join('/') || '/';

				// overwrite of the top level path as it's login screen, not actually a dashboard page
				if (pathToCheck === '/') {
					pathToCheck = `/${props.firstRoute}`;
				}

				// replace all dynamic parts of the url with params provided in the given url (usually it is once, but already built to handle multiple)
				const matched = routes.filter((currentRoute) => {
					const replacedRouterPath = pathParamsReplacer(currentRoute.path);
					return replacedRouterPath === pathToCheck;
				})[0];

				if (matched && matched.meta.title) {
					existingPaths.unshift({
						title: Core.Utils.pageTitle(matched.meta, route, props.storeData),
						link: pathParamsReplacer(matched.path)
					});
				}

				paths.pop(); // drops last item

				if (paths.length > 0) {
					return buildBreadcrumbs(routes, paths, existingPaths);
				}

				return existingPaths;
			}

			/**
			 * Replaces all dynamic elements of the path with correct values
			 * @namespace Core_Breadcrumbs
			 * @private
			 * @param {string} path from the vue router
			 * @returns {string} with final replaced path
			 */
			function pathParamsReplacer(path) {
				const currentParams = route.params;
				let replacedRouterPath = String(path);

				for (const param in currentParams) {
					replacedRouterPath = replacedRouterPath.replace(
						`:${param}`,
						currentParams[param]
					);
				}

				return replacedRouterPath;
			}

			return { Core, state, route };
		}
	};
</script>

<style lang="scss">
	@include block('Breadcrumbs') {
		@include element('heading') {
			margin-bottom: 5px;
		}
	}
</style>
