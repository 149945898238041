import * as Core from '@Core/index.js';

// STORE DEFINITIONS -------------------
/**
 * @exports Student_Store_School
 * @namespace Student_Store_School
 */
export const useSchoolStore = Core.Pinia.defineStore({
	id: 'school',

	state: () => ({
		data: {
			slug: ''
		}
	}),

	actions: {
		/**
		 * Retrieve all products from the API for current school
		 * @memberof Student_Store_School
		 */
		async init() {
			// set correct schoolslug
			const originParts = window.location.href.split('.');
			this.data.slug = originParts[0].replace('https://', '').replace('http://', '');

			// overwrite slug for local development
			if (window.location.href.includes('localhost')) {
				this.data.slug = 'student';
			}

			const response = await Core.Api.get(`student/school/${this.data.slug}`);
			const responseData = response.body.message || {};

			if (response.body.status === 200) {
				this.data = { ...responseData };

				// set school's theme data
				if (responseData.themeColor1) {
					document.documentElement.style.setProperty(
						'--color-primary',
						responseData.themeColor1
					);
				}
				if (responseData.themeColor2) {
					document.documentElement.style.setProperty(
						'--color-secondary',
						responseData.themeColor2
					);
				}
				if (responseData.themeColor3) {
					document.documentElement.style.setProperty(
						'--color-tertiary',
						responseData.themeColor3
					);
				}
			} else {
				// error
				Core.Event.trigger('ERROR', { log: responseData });
			}
		}
	},

	getters: {
		/**
		 * Return school details object
		 * @memberof Student_Store_School
		 * @param {object} state automatically passed in
		 * @returns {Array} data for design changes
		 */
		getDetails: (state) => {
			return state.data;
		}
	}
});
