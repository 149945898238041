<template>
	<div
		class="BpLoader"
		:data-testid="fullSize ? 'blueprint-loader-fullSize' : 'blueprint-loader'"
		:class="fullSize ? 'BpLoader-fullSize' : ''">
		<img src="./assets/loader.svg?url" />
		<p
			v-if="loadingText"
			class="Color-primary">
			{{ loadingText }}
		</p>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	export default {
		name: 'BlueprintLoader',

		//  ---------- PROPS ----------
		props: {
			/**
			 * property {boolean} [fullSize] - when you need full-screen loader, fixed position, etc
			 * @namespace Core_Blueprint_Loader
			 * @property {boolean} [fullSize] - when you need full-screen loader, fixed position, etc
			 */
			fullSize: {
				required: false,
				type: Boolean
			},
			/**
			 * PROP: loadingText text to show under the loader
			 * @namespace Core_Blueprint_Loader
			 * @property {string} [loadingText] text to show under the loader
			 */
			loadingText: {
				type: String,
				required: false,
				default: ''
			}
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('BpLoader') {
		text-align: center;
		margin-top: 150px;
		margin-bottom: 150px;

		& img {
			height: 100px;
			width: 100px;
		}

		@include modifier('fullSize') {
			background-color: transparentize($color-greyStart, $amount: 0.5);
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1000;
			margin: auto;
			display: flex;
			justify-content: center;

			& img {
				height: 200px;
				width: 200px;
				align-self: center;
			}
		}
	}
</style>
