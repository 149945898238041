const slugBlacklist = [
	'admin',
	'login',
	'student',
	'school',
	'pl',
	'en',
	'lt',
	'lv',
	'de',
	'fr',
	'it',
	'nl',
	'pt',
	'ru',
	'es',
	'sv',
	'no',
	'fi',
	'da',
	'cs',
	'hu',
	'ro',
	'bg',
	'fuck',
	'shit',
	'bitch',
	'bastard',
	'asshole',
	'dick',
	'pussy',
	'cunt',
	'damn',
	'hell',
	'crap',
	'whore',
	'slut',
	'prick',
	'cock',
	'douche',
	'faggot',
	'motherfucker',
	'twat',
	'wanker',
	'nigger',
	'chink',
	'spic',
	'retard',
	'arse',
	'bollocks',
	'bugger',
	'dyke',
	'gook',
	'jackass',
	'kike',
	'minger',
	'nob',
	'pecker',
	'shithead',
	'skank',
	'tosser',
	'turd',
	'wetback',
	'bint',
	'bollock',
	'git',
	'muppet',
	'piss',
	'sod',
	'arsehole',
	'knobhead',
	'numpty',
	'mong'
];

const LOCAL = {
	ENV: 'LOCAL',
	BACKEND_API:
		window.location.port === '3000' ? 'http://localhost:2323/' : 'http://localhost:2324/',
	BUCKET_SCHOOL: 'https://storage.school.kursaas.com',
	GOOGLE_KEY: 'xxx',
	SLUG_BLACKLIST: slugBlacklist
};

const PROD = {
	ENV: 'PROD',
	BACKEND_API:
		window.location.href.indexOf('school.kursaas.com') === 8
			? 'https://api.school.kursaas.com/'
			: 'https://api.student.kursaas.com/',
	BUCKET_SCHOOL: 'https://storage.school.kursaas.com',
	GOOGLE_KEY: 'xxx',
	SLUG_BLACKLIST: slugBlacklist
};

// export the right environment config
export default !window.location.href.includes('localhost') ||
window.location.href.includes('env=prod')
	? PROD
	: LOCAL;
