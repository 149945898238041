<template>
	<div class="Notifications">
		<transition-group name="fadeAbsolute">
			<div
				v-for="(item, index) in state"
				:key="index"
				class="Notifications--item"
				:class="item.type && 'Notifications--item-' + item.type">
				<div class="Notifications--box">
					<div
						v-if="item.icon"
						class="Notifications--icon"
						:class="item.icon"></div>
					{{ item.content }}
				</div>
			</div>
		</transition-group>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';

	export default {
		name: 'Notifications',

		//  ---------- SETUP ----------
		/**
		 * Alert component cannot be used directly. It is embedded within main templates and can be created/shown by Core.Event.trigger() method only.
		 * Check Events/NOTIFICATIONS section in the documentation for details.
		 * Trigger data accept data in specific format only.
		 * @see Events/NOTIFICATIONS
		 * @namespace Core_Alert
		 * @returns {object} Component
		 */
		setup() {
			const state = Core.Vue.ref({});

			const defaults = {
				type: null,
				content: '',
				icon: 'iconFont-idea'
			};

			Core.Vue.onBeforeMount(() => {
				/**
				 * Global Event.trigger() event name 'NOTIFICATIONS' to display Alert component that will audomatically disapear.
				 * This is part of Core/Blueprint/Alert component. It requires object with extra data.
				 * @event NOTIFICATIONS
				 * @see Core_Alert
				 * @param {string} [type] - define type (warning|error|success)
				 * @param {string} content - text to display
				 * @param {string} [icon] - icon classname to be added in the alert
				 */
				Core.Event.on('NOTIFICATIONS', function (name, data) {
					const notificationId = Core.Utils.makeid(10);
					state.value[notificationId] = { ...defaults, ...data };

					setTimeout(function () {
						delete state.value[notificationId];
					}, 5000);
				});
			});

			return { state };
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('Notifications') {
		position: fixed;
		right: var(--space-double);
		bottom: var(--space-double);

		@include element('item') {
			width: 350px;
			background-color: var(--color-greyStart);
			filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
			margin-top: var(--space-double);

			&::before {
				content: '';
				width: 40px;
				display: block;
				height: 40px;
				top: -14px;
				left: -14px;
				background-color: var(--color-greyStart);
				position: absolute;
				border-top-left-radius: 50%;
				border-bottom-left-radius: 50%;
				border-top-right-radius: 50%;
			}

			@include modifier('success') {
				& .Notifications--box::before {
					background-color: var(--color-stateSuccessBg);
				}

				& .Notifications--box {
					background-color: var(--color-stateSuccessBg);
					border: 1px solid var(--color-stateSuccess);

					& .Notifications--icon {
						color: var(--color-stateSuccess);
					}
				}
			}
			@include modifier('warning') {
				& .Notifications--box::before {
					background-color: var(--color-stateWarningBg);
				}

				& .Notifications--box {
					background-color: var(--color-stateWarningBg);
					border: 1px solid var(--color-stateWarning);

					& .Notifications--icon {
						color: var(--color-stateWarning);
					}
				}
			}
			@include modifier('danger') {
				& .Notifications--box::before {
					background-color: var(--color-stateDangerBg);
				}

				& .Notifications--box {
					background-color: var(--color-stateDangerBg);
					border: 1px solid var(--color-stateDanger);

					& .Notifications--icon {
						color: var(--color-stateDanger);
					}
				}
			}
		}

		@include element('box') {
			background-color: #fff;
			padding: var(--space-double);

			&::before {
				content: '';
				width: 40px;
				display: block;
				height: 40px;
				top: -14px;
				left: -14px;
				position: absolute;
				border-top-left-radius: 50%;
				border-bottom-left-radius: 50%;
				border-top-right-radius: 50%;
			}
		}

		@include element(icon) {
			top: -10px;
			left: -10px;
			font-size: 30px;
			position: absolute;
		}
	}
</style>
