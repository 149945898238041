import * as Core from '@Core/index.js';
import { useSchoolStore } from './school.js';

const DEFAULT_STORE = {
	data: {
		products: []
	},
	loaded: {
		products: false
	}
};

// STORE DEFINITIONS -------------------
/**
 * @exports Student_Store_Product
 * @namespace Student_Store_Product
 */
export const useProductStore = Core.Pinia.defineStore({
	id: 'product',

	state: () => ({
		...Core.Utils.cloneObject(DEFAULT_STORE)
	}),

	actions: {
		/**
		 * Retrieve all products from the API for current school
		 * @memberof Student_Store_Product
		 * @param {boolean} [force=false] force refresh
		 */
		async fetchProducts(force = false) {
			if (this.loaded.products && !force) return; // do nothing if already loaded

			const response = await Core.Api.get('student/products');
			const results = response.body.message || [];

			this.data.products = results;
			this.loaded.products = true;
		},

		/**
		 * Retrieve details for single course
		 * @memberof Student_Store_Product
		 * @param {string} slug course shortname
		 * @param {boolean} [force=false] force refresh
		 * @returns {Promise<boolean>} true if found
		 */
		async fetchProductDetails(slug, force = false) {
			const product = this.getProductBySlug(slug);
			if ((!product || product.content) && !force) return true; // do nothing if already loaded

			const response = await Core.Api.get(`student/products-details/${product.id}`);

			if (response.status === 409) {
				// means it doesn't exist
				return false;
			}

			const responseData = response.body.message || {};
			for (let i = 0; i < this.data.products.length; i++) {
				if (this.data.products[i].id === product.id) {
					this.data.products[i] = responseData;
					break;
				}
			}
			return true;
		},

		/**
		 * Fetch lessons details
		 * @memberof Student_Store_Product
		 * @param {number} lessonId lesson ID
		 * @param {string} slug product slug
		 * @param {boolean} [force=false] force refresh
		 * @returns {Promise<boolean>} true if found
		 */
		async fetchLesson(lessonId, slug, force = false) {
			const product = this.getProductBySlug(slug);
			if (product.lessons[lessonId].content && !force) return true; // do nothing if already loaded

			const response = await Core.Api.get(`student/products-details-lesson/${lessonId}`);
			const lessonDetails = response.body.message;

			if (response.status === 200) {
				const productFromStore = this.data.products.find(
					(productItem) => productItem.id === lessonDetails.productId
				);

				const mergedData = Core.Utils.mergeDeep(
					productFromStore.lessons[lessonDetails.id],
					lessonDetails
				);

				productFromStore.lessons[lessonDetails.id] = mergedData;
			}

			return response.status;
		}
	},

	getters: {
		/**
		 * Return all courses
		 * @memberof Student_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Array} data with all courses
		 */
		getCourses: (state) => {
			return state.data.products?.filter((product) => product.type === 'COURSE');
		},

		/**
		 * Return all downloads
		 * @memberof Student_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Array} data with all digital downloads
		 */
		getDownloads: (state) => {
			return state.data.products.filter((product) => product.type === 'DOWNLOAD');
		},

		/**
		 * Return all articles
		 * @memberof Student_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Array} data with all digital articles
		 */
		getArticles: (state) => {
			return state.data.products.filter((product) => product.type === 'ARTICLE');
		},

		/**
		 * Return one product based on ID and type
		 * @memberof Student_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {object} data with all articles
		 */
		getProductById: (state) => {
			return (id) => {
				return state.data.products?.find((product) => product.id === id) || {};
			};
		},

		/**
		 * Return product details by slug
		 * @memberof Student_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {object} data with all articles
		 */
		getProductBySlug: (state) => {
			return (slug) => {
				return state.data.products.find((prod) => prod.slug === slug);
			};
		},

		/**
		 *  Return lesson details by product slug and lesson ID
		 * @memberof Student_Store_Product
		 * @returns {Array} data with a given lesson details
		 */
		getLesson: function () {
			return (productSlug, lessonId) => {
				const product = this.getProductBySlug(productSlug);
				return product?.lessons?.[lessonId] || {};
			};
		},

		/**
		 * Gets all categories
		 * @memberof Student_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Array} returns all categories from all products
		 */
		getAllCategories: (state) => {
			let allCategories = [];

			for (let i = 0; i < state.data.products.length; i++) {
				allCategories = [...allCategories, ...state.data.products[i].categories];
			}

			// return unique records
			return [...new Set(allCategories)];
		},

		/**
		 * Gets all authors
		 * @memberof Student_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Array} returns all authors from all products
		 */
		getAllAuthors: (state) => {
			const allAuthors = [];

			for (let i = 0; i < state.data.products.length; i++) {
				allAuthors.push(state.data.products[i].author);
			}

			// return unique records
			return [...new Set(allAuthors)];
		}
	}
});
