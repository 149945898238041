<template>
	<div class="container-fluid DesignColor">
		<div class="row DesignColor--wrapperRow align-items-center">
			<div class="col-md-8 offset-md-1">
				<img src="@Core/assets/template-color-image.png?url" />
			</div>
			<div class="col-md-13 offset-md-1">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	/**
	 * Design template for our applications. Default screen without any columns or extra stuff. Just one, main container but with footer and header
	 * @vue-prop {*} slot:unnamed slot for your main content or component
	 * @namespace Core_Design_Color
	 */

	export default {
		name: 'DesignColor'
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('DesignColor') {
		background-blend-mode: overlay;
		background-image: url(@Core/assets/template-color-background.jpg?url),
			linear-gradient(180deg, #474ab6 0%, #219ebc 100%) !important;
		background-size: cover;
		position: relative;
		top: 0;
		bottom: 0;
		padding-top: var(--space-double);
		padding-bottom: var(--space-double);

		@include element('wrapperRow') {
			padding-top: var(--space-double);
			height: 100%;
			min-height: calc(100vh - 60px);
		}
	}
</style>
