<template>
	<div
		class="ProgressLine"
		data-testid="progress-line">
		<div class="row no-gutters align-items-center">
			<div class="ProgressLine--empty col-lg-16 col-sm-14">
				<div
					class="ProgressLine--full"
					data-testid="progress-line-full"
					:style="getStyle()"></div>
			</div>
			<div class="ProgressLine--number Space-left col-sm-4 col-lg-5">
				{{ Math.floor(progress) }}%
				<span
					v-if="extraLabel"
					class="Color-grey600"
					>{{ extraLabel }}</span
				>
			</div>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';

	export default {
		name: 'BlueprintProgressLine',

		//  ---------- PROPS ----------
		props: {
			/**
			 * property {number} [progress=0] - Set the title and it has to be a string
			 * @namespace Core_Blueprint_ProgressLine
			 * @property {number} [progress=0] - Set the title and it has to be a string
			 */
			progress: {
				type: Number,
				required: false,
				default: 0
			},
			/**
			 * PROP: set the title and it has to be a string
			 * @namespace Core_Blueprint_ProgressLine
			 * @property {string} [extraLabel=''] Set the title and it has to be a string
			 */
			extraLabel: {
				type: String,
				required: false,
				default: null
			}
		},

		//  ---------- SETUP ----------
		setup(props) {
			const colorMap = {
				0: 'background-color: var(--color-stateDanger)',
				25: 'background-color: var(--color-stateWarning)',
				50: 'background-color: var(--color-primary)',
				99: 'background-color: var(--color-stateSuccess)'
			};

			const colors = Core.Vue.computed(() => {
				let finalColors = {};
				for (const item in colorMap) {
					if (props.progress >= item) {
						finalColors = colorMap[item];
					}
				}
				return finalColors;
			});

			/**
			 * return final inline styles to be applied to progress half-bits
			 * @returns {object} concatenated styles to display
			 */
			function getStyle() {
				return props.progress === 0
					? `width: 0.5% ; ${colors.value};`
					: `width: ${Math.floor(props.progress)}%; ${colors.value};`;
			}

			return { getStyle };
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('ProgressLine') {
		@include element('empty') {
			border-radius: var(--radius-small);
			overflow: hidden;
			height: 10px;
			display: inline-block;
			background-color: var(--color-grey900);
		}
		@include element('full') {
			background-color: var(--color-primary);
			height: 10px;
			transition: all var(--time-transition) ease;
		}

		@include element('number') {
			display: inline-block;
			text-align: left;
			padding-left: 10px;
			font-size: var(--text-tiny);
		}
	}
</style>
