<template>
	<div>
		<div
			class="BpForms BpHorizontalRadio"
			:class="currentClasses"
			:data-testid="id + '-horizontalRadio'">
			<p
				v-if="label"
				class="BpForms--label BpHorizontalRadio--label">
				{{ label }}
				<transition name="fade">
					<span
						v-if="labelNote"
						class="Text-small BpHorizontalRadio--labelNote">
						{{ labelNote }}</span
					>
				</transition>
				<span class="BpForms--optional">{{ language.string.cForms.optional }}</span>
				<span
					v-if="tooltip"
					class="Tooltip Tooltip-bottom"
					:data-tooltip="tooltip"
					><span class="Tooltip-icon BpForms--tooltip"></span
				></span>
			</p>
			<div class="row BpHorizontalRadio--wrapper">
				<div
					v-for="(option, index) in internalOptions"
					:key="option.value"
					class="align-self-stretch Space-bottom"
					:class="optionWidth">
					<button
						class="BpHorizontalRadio--option"
						:class="optionClasses(option.value, index)"
						:disabled="disabled"
						data-testid="horizontalRadio-option"
						@click="!disabled && (computedValue = option.value)">
						<p
							v-if="option.name"
							class="BpHorizontalRadio--name"
							:class="(option.icon || option.text) && 'Space-bottom'">
							{{ option.name }}
						</p>

						<span
							v-if="option.icon && option.icon.indexOf('iconFont-') > -1"
							class="d-none d-md-block BpHorizontalRadio--icon"
							:class="option.icon"></span>
						<img
							v-else-if="option.icon"
							class="d-none d-md-inline-block BpHorizontalRadio--icon"
							:src="option.icon" />
						<p v-if="option.text">
							{{ option.text }}
						</p>
					</button>
					<!-- Selected note property visible only when selected, outside of option -->
					<transition name="slide-down">
						<div
							v-show="option.selectedNote && computedValue === option.value"
							class="BpHorizontalRadio--selectedNote Text-small Color-secondary">
							{{ option.selectedNote }}
						</div>
					</transition>
				</div>
			</div>

			<transition name="slide-down">
				<blueprint-input
					v-if="showOtherField"
					:id="id + 'other'"
					v-model:value="textFieldValue"
					class="BpHorizontalRadio--input BpHorizontalRadio--textInput"
					data-testid="other-input"
					:max-length="30"
					:placeholder="language.string.cForms.moreInfo" />
			</transition>

			<transition name="slide-down">
				<div
					v-if="currentMsg"
					class="BpForms--msg BpHorizontalRadio--msg">
					{{ currentMsg }}
				</div>
			</transition>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';
	import * as Helpers from './helpers.js';
	import { useLanguageStore } from '@Core/store/language.js';

	export default {
		name: 'BlueprintHorizontalRadio',

		// ---------- PROPS ----------
		props: {
			/**
			 * property {string} id - unique id
			 * @namespace  Core_Blueprint_HorizontalRadio
			 * @property {string} id - unique id
			 */
			id: {
				type: String,
				required: true
			},

			/**
			 * property {string} [label] - label, or title
			 * @namespace Core_Blueprint_HorizontalRadio
			 * @property {string} [label] - label, or title
			 */
			label: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {string} [labelNote] - note next to label
			 * @namespace Core_Blueprint_HorizontalRadio
			 * @property {string} [labelNote] - note next to label
			 */
			labelNote: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {string | boolean | number} [value] - bind your value
			 * @namespace Core_Blueprint_HorizontalRadio
			 * @property {string | boolean | number} [value] - bind your value
			 */
			value: {
				type: [String, Boolean, Number],
				required: false,
				default: null
			},

			/**
			 * property {Array} options - array of objects with all settings: name, value, icon, text, selectedNote
			 * @namespace Core_Blueprint_HorizontalRadio
			 * @property {Array} options - array of objects with all settings: name, value, icon, text, selectedNote
			 */
			options: {
				required: true,
				type: Array
			},

			/**
			 * property {boolean} [showOther] - add "other option" automatically with (as default will include free input)
			 * @namespace Core_Blueprint_HorizontalRadio
			 * @property {boolean} [showOther] - add "other option" automatically with (as default will include free input)
			 */
			showOther: {
				required: false,
				type: Boolean
			},

			/**
			 * property {string} [otherText] - 'other' option Text/label
			 * @namespace Core_Blueprint_HorizontalRadio
			 * @property {string} [otherText] - 'other' option Text/label
			 */
			otherText: {
				required: false,
				type: String,
				default: null
			},

			/**
			 * property {boolean | string} [otherIcon] - set to true for default icon, pass string to set custom one
			 * @namespace Core_Blueprint_HorizontalRadio
			 * @property {boolean | string} [otherIcon] - set to true for default icon, pass string to set custom one
			 */
			otherIcon: {
				required: false,
				type: [Boolean, String],
				default: null
			},

			/**
			 * property {string} [required=false] - is this field required?
			 * @namespace Core_Blueprint_HorizontalRadio
			 * @property {string} [required=false] - is this field required?
			 */
			required: {
				type: Boolean,
				required: false
			},

			/**
			 * property {boolean} [disabled] - should this field be "disabled"
			 * @namespace Core_Blueprint_HorizontalRadio
			 * @property {boolean} [disabled] - should this field be "disabled"
			 */
			disabled: {
				type: Boolean,
				required: false
			},

			/**
			 * property {boolean} [center] - center the whole content
			 * @namespace Core_Blueprint_HorizontalRadio
			 * @property {boolean} [center] - center the whole content
			 */
			center: {
				type: Boolean,
				required: false
			},

			/**
			 * property {string} [msg] - message to display below the field
			 * @namespace Core_Blueprint_HorizontalRadio
			 * @property {string} [msg] - message to display below the field
			 */
			msg: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {string} [msgType] - type of the message
			 * @namespace Core_Blueprint_HorizontalRadio
			 * @property {string} [msgType] - type of the message
			 */
			msgType: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {string} [tooltip] - content to be shown as tooltip within label area
			 * @namespace Core_Blueprint_HorizontalRadio
			 * @property {string} [tooltip] - content to be shown as tooltip within label area
			 */
			tooltip: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {boolean} [showValidationText=true] - do we want validation text to be shown
			 * @namespace Core_Blueprint_Input
			 * @property {boolean} [showValidationText=true] - do we want validation text to be shown
			 */
			showValidationText: {
				type: Boolean,
				required: false,
				default: true
			}
		},

		//  ---------- EMITS ----------
		emits: ['update:value'],

		// ---------- SETUP ----------
		setup(props, context) {
			const language = useLanguageStore();

			const computedValue = Core.Vue.computed({
				get() {
					return props.value;
				},
				set(newValue) {
					context.emit('update:value', newValue);
				}
			});

			// list of allowed values (parsed options)
			const allowedValues = Core.Vue.computed(() => {
				const values = [];
				for (const item in props.options) {
					values.push(props.options[item].value);
				}
				return values;
			});

			// figure out if other field should be selected and shown
			const showOtherField = Core.Vue.computed(() => {
				// check if value is totaly ouf of the fucking world and show "other field"
				const computedOnAllowed = allowedValues.value.filter((item) => {
					return computedValue.value === item;
				});
				if (computedOnAllowed < 1) {
					textFieldValue.value = computedValue.value;
				}

				// if no default value has been set, make sure "other" is not selected
				if (typeof computedValue.value === 'undefined' || computedValue.value === null) {
					return false;
				}

				if ((computedValue.value === '' || computedOnAllowed < 1) && props.showOther) {
					return true;
				}

				return false;
			});

			const textFieldValue = Core.Vue.ref();
			const currentMsg = Core.Vue.ref(props.msg);
			const currentMsgType = Core.Vue.ref(props.msgType || 'notification');

			// add extra item to the list if "other" is required (only once!)
			const internalOptions = Core.Vue.computed(() => {
				const myOptions = Core.Utils.cloneObject(props.options);
				if (props.showOther) {
					myOptions.push({
						name: 'Other',
						value: '',
						icon: props.otherIcon === true ? 'iconFont-plus' : props.otherIcon,
						text: props.otherText
					});
				}
				return myOptions;
			});

			// option width based on number of elements
			const optionWidth = Core.Vue.computed(() => {
				// if less than 4 options, divide by less :)
				if (internalOptions.value.length < 4) {
					return `col-${24 / internalOptions.value.length}`;
				} else {
					return 'col-12 col-sm-12 col-md-6 col-lg-6';
				}
			});

			// the whole wrapper classes
			const currentClasses = Core.Vue.computed(
				() =>
					`${props.disabled ? 'is-disabled' : ''} ${props.center ? 'is-center' : ''} ${
						props.required ? 'is-required' : ''
					} ${currentMsgType.value ? `is-${currentMsgType.value}` : ''}`
			);

			// validate whenever value changes
			Core.Vue.watch(computedValue, (currentValue) => {
				if (
					currentValue !== null &&
					currentValue !== '' &&
					typeof currentValue !== 'undefined'
				) {
					validate();
				}
			});

			// set the value whenever textfield changed
			Core.Vue.watch(textFieldValue, (currentValue) => {
				computedValue.value = currentValue;
			});

			/**
			 * This is a function that returns the classes for every option.
			 * @param {string | boolean} value from the filed we are looping
			 * @returns {string} final set of classes
			 */
			function optionClasses(value) {
				let finalClasses = '';

				// set class as needed
				if (value === computedValue.value) {
					finalClasses = 'is-selected ';
				} else if (props.showOther && value === '' && showOtherField.value) {
					finalClasses = 'is-selected ';
				}

				return finalClasses;
			}

			// -------------- VALIDATION  --------------
			const validated = Core.Vue.ref(true);

			/**
			 * validate() validation function to check the input on string if it's alligned with the requirements.
			 * @namespace Core_Blueprint_HorizontalRadio
			 * @returns {boolean} result of validation
			 */
			function validate() {
				// if required
				let validationValue = props.value;

				validationValue =
					typeof validationValue === 'string' ? validationValue.trim() : validationValue;

				// check if value is on list of options (in case if one of them is "false" option)
				let allowFalse = false;
				for (const item in props.options) {
					if (props.options[item].value === false) {
						allowFalse = true;
					}
				}

				if (
					props.required &&
					(validationValue === '' ||
						(validationValue === false && !allowFalse) ||
						validationValue === null ||
						typeof validationValue === 'undefined')
				) {
					showMsg('error', language.string.cForms.missingField);
					validated.value = false;
				} else {
					showMsg();
				}

				return validated.value;
			}

			/**
			 * Reset field to initial state
			 *
			 */
			function reset() {
				Helpers.resetAll({ currentMsg, currentMsgType }, { value: computedValue });
			}

			/**
			 * it will populate the correct values to make sure message is visibile
			 * if vars not provied, clean the messages and validate as all ok
			 * @param {string} type of error
			 * @param {string} content of the error
			 */
			function showMsg(type, content) {
				currentMsgType.value = type;
				currentMsg.value = content;

				if (type === 'error') {
					validated.value = false;
				}

				if (!type && !content) {
					validated.value = true;
				}
			}

			return {
				language,
				internalOptions,
				currentClasses,
				currentMsg,
				optionWidth,
				optionClasses,
				validate,
				reset,
				showMsg,
				computedValue,
				showOtherField,
				textFieldValue
			};
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('BpHorizontalRadio') {
		text-align: left;
		margin-bottom: -15px;

		@include element('wrapper') {
			display: flex;
			align-items: flex-start;
		}

		@include element('labelNote') {
			font-weight: var(--weight-regular);
		}

		@include element('option') {
			display: block;
			width: 100%;
			height: 100%;
			border: 1px solid currentColor;
			cursor: pointer;
			padding: var(--space-single);
			user-select: none;
			color: var(--color-grey600);
			border-radius: var(--radius-big);
			transition: all var(--time-hover) ease-in-out;

			* {
				transition: var(--time-hover) ease-out;
				transition-property: color, border-color, background-color;
			}
		}

		@include element('icon') {
			font-size: 40px !important;
			display: inline-block;
			height: 50px;
			margin-bottom: 5px;
		}

		@include element('input') {
			margin-top: 0 !important;
		}

		@include element('msg') {
			margin-top: -10px !important;
		}

		@include element('selectedNote') {
			margin-top: 3px;
		}

		// style states
		&:not(.is-disabled) {
			.BpHorizontalRadio--option.is-selected,
			.BpHorizontalRadio--option:hover,
			.BpHorizontalRadio--option:focus {
				color: var(--color-secondary);
			}
		}

		&.is-error .BpHorizontalRadio--option {
			border-color: var(--color-stateDanger);
			border-width: 2px;
		}

		&.is-success .BpHorizontalRadio--option {
			border-color: var(--color-stateSuccess);
			border-width: 2px;
		}

		&.is-warning .BpHorizontalRadio--option {
			border-color: var(--color-stateWarning);
			border-width: 2px;
		}

		&.is-disabled .BpHorizontalRadio--option {
			background-color: var(--color-grey900);
			cursor: default;
		}

		&.is-center {
			.BpHorizontalRadio--option,
			.BpHorizontalRadio--selectedNote {
				text-align: center;
			}
		}
	}
</style>
