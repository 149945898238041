<template>
	<div>
		<div
			class="FancyToggle BpForms"
			:class="state.showValidationStyles && currentClasses"
			:data-testid="id + '-fancyToggle'">
			<p class="BpForms--label Space-bottom">{{ state.label }}</p>
			<div class="BpForms--input FancyToggle--inputWrapper">
				<label
					v-for="(option, index) in state.options"
					:key="index"
					:for="state.id + index"
					class="FancyToggle--option"
					:class="{
						'FancyToggle--option-selected': option.value === state.value,
						'FancyToggle--option-disabled': state.disabled
					}">
					<input
						:id="state.id + index"
						:name="state.name"
						type="radio"
						class="d-none"
						:disabled="state.disabled"
						:value="option.value"
						:checked="option.value === state.value"
						@input="$emit('update:value', $event.target.value), validate()" />
					{{ option.name }}
				</label>
			</div>
			<transition name="slide-down">
				<div
					v-if="internal.currentMsg"
					class="BpForms--msg Space-left"
					:data-testid="`blueprint-fancyToggle-message-type-${state.msgType}`">
					{{ internal.currentMsg }}
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
	import * as Core from '@Core/index.js';
	import * as Helpers from './helpers.js';
	export default {
		name: 'BlueprintFancyToggle',
		// ---------- PROPS ----------
		props: {
			/**
			 * property {string} id - unique id
			 * @namespace Core_Blueprint_FancyToggle
			 * @property {string} id - unique id
			 */
			id: {
				type: String,
				required: true
			},
			/**
			 * property {string} name - name of the field
			 * @namespace Core_Blueprint_FancyToggle
			 * @property {string} name - name of the field
			 */
			name: {
				type: String,
				required: true
			},
			/**
			 * property {Array} options - options for the field
			 * @namespace Core_Blueprint_FancyToggle
			 * @property {Array} options - options for the field
			 */
			options: {
				type: Array,
				required: true
			},
			/**
			 * property {string} value - value of the field
			 * @namespace Core_Blueprint_FancyToggle
			 * @property {string} value - value of the field
			 */
			value: {
				type: String,
				required: true
			},
			/**
			 * property {boolean} [required=false] - should this field be "required"
			 * @namespace Core_Blueprint_FancyToggle
			 * @property {boolean} [required=false] - should this field be "required"
			 */
			required: {
				required: false,
				type: Boolean,
				default: false
			},
			/**
			 * property {string} [label=''] - label for the field
			 * @namespace Core_Blueprint_FancyToggle
			 * @property {string} [label=''] - label for the field
			 */
			label: {
				type: String,
				required: false,
				default: null
			},
			/**
			 * property {boolean} [disabled=false] - should this field be "disabled"
			 * @namespace Core_Blueprint_FancyToggle
			 * @property {boolean} [disabled=false] - should this field be "disabled"
			 */
			disabled: {
				type: Boolean,
				required: false,
				default: false
			},
			/**
			 * property {string} [msg] - message to display below the field
			 * @namespace Core_Blueprint_FancyToggle
			 * @property {string} [msg] - message to display below the field
			 */
			msg: {
				type: String,
				required: false,
				default: undefined
			},

			/**
			 * property {string} [msgType=notification] - type of the message
			 * @namespace Core_Blueprint_FancyToggle
			 * @property {string} [msgType=notification] - type of the message
			 */
			msgType: {
				type: String,
				required: false,
				validator: function (value) {
					return ['notification', 'warning', 'success', 'error'].includes(value);
				},
				default: 'notification'
			},
			/**
			 * property {boolean} [showValidationText=true] - do we want validation text to be shown
			 * @namespace Core_Blueprint_FancyToggle
			 * @property {boolean} [showValidationText=true] - do we want validation text to be shown
			 */
			showValidationText: {
				type: Boolean,
				required: false,
				default: true
			},
			/**
			 * property {boolean} showValidationStyles - do we want to show validation styles or not
			 * @namespace Core_Blueprint_FancyToggle
			 * @property {boolean} showValidationStyles - do we want to show validation styles or not
			 */
			showValidationStyles: {
				type: Boolean,
				required: false,
				default: true
			}
		},
		// ---------- EMITS ----------
		emits: ['update:value'],
		// ---------- SETUP ----------
		setup(props) {
			// reactive props, that's all
			const state = Core.Vue.computed(() => {
				return {
					...props // make all props reactive
				};
			});

			// internal values only (can be set from inside or outside)
			const internal = Core.Vue.reactive({
				currentMsg: state.value.msg,
				currentMsgType: state.value.msgType
			});

			// our computed and transformed values to use as we need (from props)
			const currentClasses = Core.Vue.computed(() =>
				Helpers.resolveClassNames(state.value, internal)
			);

			/**
			 * Validate the field
			 * @namespace Core_Blueprint_FancyToggle
			 * @returns {boolean} is field valid
			 */
			function validate() {
				return Helpers.validators(state, internal);
			}

			return { currentClasses, validate, internal, state };
		}
	};
</script>

<style lang="scss">
	@include block('FancyToggle') {
		@include element('inputWrapper') {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: fit-content;
			border: 2px solid var(--color-grey800);
			padding: 4px;
		}
		@include element('option') {
			width: 100%;
			padding: 11px var(--space-single);
			color: var(--color-grey600);
			text-align: center;
			transition: var(--time-transition) ease-in-out;
			&:hover {
				cursor: pointer;
			}

			@include modifier('disabled') {
				opacity: 0.5;
			}

			@include modifier('selected') {
				background-color: var(--color-secondary);
				color: var(--color-greyStart);
			}
		}
	}
</style>
