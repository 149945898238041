<template>
	<div class="Payments justify-content-center">
		<template v-if="!product.enrolled">
			<button
				v-if="!loggedIn"
				class="Btn Btn-fullWidth"
				@click="!loggedIn ? Core.Event.trigger('LOGIN_POPUP') : start()">
				{{ language.string.cPayments.btnSignPurchase }}
			</button>

			<button
				v-else-if="product.price === 0"
				class="Btn Btn-fullWidth"
				@click="enrollForFree()">
				Enroll for free
			</button>

			<button
				v-else
				class="Btn Btn-fullWidth"
				@click="!loggedIn ? Core.Event.trigger('LOGIN_POPUP') : start()">
				<span v-if="!loggedIn">Sign in & </span>{{ language.string.cPayments.btnPurchase }}
			</button>
		</template>

		<blueprint-popup
			ref="popup"
			:header="language.string.cPayments.popupTitle">
			<template #default>
				<div v-if="paymentCompletion">
					<blueprint-loader
						v-if="!statusSuccess && !statusError"
						class="Space-bottom" />

					<!-- STATUS SUCCESS MSG  -->
					<div
						v-else-if="statusSuccess"
						class="col-24 Space-top Space-bottomDouble">
						<div class="row">
							<div class="d-none d-sm-block col-sm-6 Text-center align-self-center">
								<div
									class="Payments--icon Payments--icon-success iconFont-tick"></div>
							</div>
							<div class="col-24 col-sm-18 align-self-center">
								<p>{{ statusSuccess }}</p>
								<div
									class="Btn"
									@click="$refs.popup.toggle()">
									{{ language.string.cPayments.btnContinue }}
								</div>
							</div>
						</div>
					</div>

					<!-- STATUS ERROR MSG  -->
					<div
						v-else-if="statusError"
						class="col-24 Space-top Space-bottomDouble">
						<div class="row">
							<div class="d-none d-sm-block col-sm-6 Text-center align-self-center">
								<div class="Payments--icon iconFont-problem"></div>
							</div>
							<div class="col-24 col-sm-18 align-self-center">
								<p>{{ statusError }}</p>
								<div
									class="Btn"
									@click="$refs.popup.toggle()">
									{{ language.string.cPayments.btnContinue }}
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- STRIPE PAYMENT FORM  -->
				<div
					v-else
					class="row">
					<div class="col-6">
						<img
							:src="product.image"
							alt="Product image"
							class="Payments--productImage" />
					</div>
					<div class="col-18">
						<div class="Text-h3 Space-topNone">{{ product.title }}</div>
						<div class="Text-small">{{ product.description }}</div>
					</div>

					<div class="col-24 Space-top">
						<form id="payment-form">
							<div
								id="payment-element"
								class="Payments--form Space-top">
								<blueprint-loader />
							</div>

							<button
								id="submit"
								class="Btn Btn-fullWidth"
								:class="paymentProcessing && 'Btn-loading'">
								<div
									v-if="paymentError"
									class="BpPill BpPill-danger Payments--paymentError">
									{{ paymentError }}
								</div>
								<span
									v-else
									id="button-text"
									>{{ language.string.cPayments.btnPay }}
									{{ Core.Utils.displayPrice(totalPrice, currency) }}</span
								>
							</button>
						</form>

						<div class="row align-items-center Space-topDouble">
							<img
								src="./assets/stripe-logo.png"
								class="col-6 Text-center"
								alt="Logo Stripe" />

							<div class="col-18 Text-small">
								{{ language.string.cPayments.desc }}
							</div>
						</div>
					</div>
				</div>
			</template>
		</blueprint-popup>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';

	export default {
		name: 'Payments',

		// ---------- PROPS ----------
		props: {
			/**
			 * PROP: product object of the given product
			 * @namespace Client_Components_Payments
			 * @property {object} product object of the given product
			 */
			product: {
				required: true,
				type: Object
			},

			/**
			 * PROP: schoolId of the given product
			 * @namespace Client_Components_Payments
			 * @property {string} schoolId of the given product
			 */
			schoolId: {
				required: true,
				type: String
			},

			/**
			 * PROP: currency of the given product
			 * @namespace Client_Components_Payments
			 * @property {string} currency of the given product
			 */
			currency: {
				required: true,
				type: String
			},

			/**
			 * PROP: userId of the current user
			 * @namespace Client_Components_Payments
			 * @property {string} userId of the current user
			 */
			userId: {
				required: true,
				type: String
			},

			/**
			 * PROP: stripeKey of the current user
			 * @namespace Client_Components_Payments
			 * @property {string} stripeKey of the current user
			 */
			stripeKey: {
				required: true,
				type: String
			},

			/**
			 * PROP: language code for checkout
			 * @namespace Client_Components_Payments
			 * @property {string} language code for checkout
			 */
			uiLang: {
				required: true,
				type: String
			},

			/**
			 * PROP: loggedIn status of the user
			 * @namespace Client_Components_Payments
			 * @property {boolean} loggedIn status of the user
			 */
			loggedIn: {
				type: Boolean,
				default: false
			}
		},

		// ---------- EMITS ----------
		emits: ['payment-success'],

		//  ---------- SETUP ----------
		/* globals Stripe */
		setup(props, context) {
			const language = useLanguageStore();
			const popup = Core.Vue.ref();

			let elements;
			let stripe;
			let isInit = false;

			const totalPrice = Core.Vue.ref();
			const paymentError = Core.Vue.ref();
			const paymentProcessing = Core.Vue.ref(false);
			const statusError = Core.Vue.ref();
			const statusSuccess = Core.Vue.ref();
			const paymentCompletion = Core.Vue.ref(false);

			// on load, check if we are not in progress of payment already
			Core.Vue.onMounted(async () => {
				// means payment has ended, we need to check status
				const clientSecret = Core.Utils.queryValue('payment_intent_client_secret');
				if (clientSecret) {
					paymentCompletion.value = true;
					await loadScript();
					await checkStatus(clientSecret);

					// cleanup after payment
					setTimeout(function () {
						window.history.replaceState({}, document.title, window.location.pathname);
					}, 1000);
				}
			});

			/**
			 * Initialise the payment popup with all dependencies
			 * We don't want to load these stuff unless it started
			 * @namespace Client_Components_Payments
			 */
			async function start() {
				popup.value.toggle();

				// make the intent and ask for card details
				const response = await Core.Api.post('student/purchase-intent', {
					productId: props.product.id,
					userId: props.userId
				});
				const clientSecret = response.body.message.clientSecret;
				totalPrice.value = props.product.price;

				paymentCompletion.value = false; // in case we need to re-open payment on this page
				await loadScript();

				const appearance = {
					theme: 'stripe'
				};

				elements = stripe.elements({ appearance, clientSecret });

				const paymentElement = elements.create('payment');
				paymentElement.mount('#payment-element');
				document.querySelector('#payment-form').addEventListener('submit', submitPayment);
			}

			/**
			 * When product is free, no purchase is needed and we just need to enroll the user
			 */
			async function enrollForFree() {
				const response = await Core.Api.post('student/purchase-intent', {
					productId: props.product.id,
					userId: props.userId
				});

				if (response.body.status === 200) {
					popup.value.toggle();
					statusSuccess.value = 'You have successfully enrolled!';
					paymentCompletion.value = true;
					context.emit('payment-success');
				}

				return;
			}

			/**
			 * Loads stripe script (once to prevent duplicate scripts)
			 */
			async function loadScript() {
				if (isInit) return;

				if (!document.getElementById('stripeScript')) {
					await Core.Utils.injectScript(
						'https://js.stripe.com/v3/',
						'stripeScript',
						false
					);
				}

				if (!stripe) {
					stripe = Stripe(props.stripeKey, {
						locale: props.uiLang
					});
				}

				isInit = true;
			}

			/**
			 * checks payment status after coming back
			 * @param {string} clientSecret from the intent response (url query param)
			 */
			async function checkStatus(clientSecret) {
				let success = false;
				if (!clientSecret) {
					return;
				}

				// on PROD, we check intent with stripe as backend will receive by webhook.
				const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
				popup.value.toggle();
				switch (paymentIntent.status) {
					case 'succeeded':
						statusSuccess.value = 'Payment succeeded!';
						success = true;
						break;
					case 'processing':
						statusError.value = 'Your payment is processing.';
						break;
					case 'requires_payment_method':
						statusError.value = 'Your payment is processing.';
						break;
					default:
						statusError.value = 'Something went wrong.';
						break;
				}

				// refresh data in case of delays
				if (success) {
					setTimeout(function () {
						context.emit('payment-success');
					}, 1000);
				}
			}

			// ----------------- STRIPE PAYMENT HANDLING AND SETUP --------------------
			/**
			 * after submit it will show error if necessary or go to stripe and redirected back to set return_url
			 * @param {object} e click event
			 */
			async function submitPayment(e) {
				try {
					e.preventDefault();
					paymentProcessing.value = true;

					await stripe.confirmPayment({
						elements,
						confirmParams: {
							// eslint-disable-next-line camelcase
							return_url: window.location.href
						}
					});
				} catch (error) {
					// if immediate error happened, show it straight away.
					if (error.type === 'card_error' || error.type === 'validation_error') {
						paymentError.value = error.message;
					} else {
						paymentError.value = language.string.cPayments.errorUnexpected;
					}
				}

				// cleanup after payment
				setTimeout(function () {
					paymentError.value = '';
				}, 1000);
				paymentProcessing.value = false;
			}

			return {
				Core,
				start,
				language,
				totalPrice,
				paymentError,
				paymentProcessing,
				statusError,
				statusSuccess,
				paymentCompletion,
				popup,
				enrollForFree
			};
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('Payments') {
		@include element('form') {
			min-height: 200px;
		}

		@include element('paymentError') {
			padding-top: 0;
			padding-bottom: 0;
			margin-top: 0;
			margin-bottom: 0;
		}

		@include element('icon') {
			font-size: 100px !important;
			line-height: 100px;
			color: var(--color-grey500);

			@include modifier('success') {
				color: var(--color-stateSuccess);
			}
		}
	}
</style>
