<template>
	<div
		v-if="showPopup"
		data-testid="popup"
		class="BpPopup Text-left"
		:class="!showPopupAnimation && 'is-hidden'"
		@click.self="toggle()">
		<div class="container BpPopup--container">
			<div
				class="row BpPopup--row"
				@click.self="toggle()">
				<div
					class="align-self-center"
					:class="
						size ||
						'col-24 col-sm-22 col-md-14 col-lg-12 offset-sm-1 offset-md-5 offset-lg-6'
					">
					<transition
						name="vanish"
						mode="out-in">
						<div
							v-show="showPopupAnimation"
							class="row Box BpPopup--box">
							<div class="col-24 BpPopup--header">
								<p
									v-if="header"
									class="BpPopup--title"
									data-test="header">
									{{ header }}
								</p>
								<hr
									v-if="header"
									class="BpPopup--hr" />

								<div
									v-if="!noClose"
									class="BpPopup--close"
									data-testid="close-icon"
									@click="toggle()">
									<div class="iconFont-cross"></div>
								</div>
							</div>

							<div
								class="col-24 BpPopup--main"
								data-test="main-content">
								<slot></slot>
								<component
									:is="useComponent"
									v-if="useComponent"
									:lazy-data="lazyData"></component>
							</div>
						</div>
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';

	export default {
		name: 'BlueprintPopup',

		//  ---------- PROPS ----------
		props: {
			/**
			 * property {string} [header] - Set the title and it has to be a string
			 * @namespace Core_Blueprint_Popup
			 * @property {string} [header] - Set the title and it has to be a string
			 */
			header: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {string} [size="col-24 col-sm-22 col-md-14 col-lg-12 offset-sm-1 offset-md-5 offset-lg-6"] - Size of the box defined in column (grid system).
			 * @namespace Core_Blueprint_Popup
			 * @property {string} [size="col-24 col-sm-22 col-md-14 col-lg-12 offset-sm-1 offset-md-5 offset-lg-6"] - Size of the box defined in column (grid system).
			 */
			size: {
				type: String,
				required: false,
				default: 'col-24 col-sm-22 col-md-14 col-lg-12 offset-sm-1 offset-md-5 offset-lg-6'
			},

			/**
			 * property {boolean} [noClose] - removes default CLOSE button, you need to handle it somehow!!!!
			 * @namespace Core_Blueprint_Popup
			 * @property {boolean} [noClose] - removes default CLOSE button, you need to handle it somehow!!!!
			 */
			noClose: {
				type: Boolean,
				required: false
			},

			/**
			 * property {object} [lazyComponent] - Provide lazy-loaded imported component
			 * @namespace Core_Blueprint_Popup
			 * @property {object} [lazyComponent] - Provide lazy-loaded imported component
			 */
			lazyComponent: {
				type: Object,
				required: false,
				default: null
			},

			/**
			 * property {object} [lazyData={}] - provide data for lazy-loaded component
			 * @namespace Core_Blueprint_Popup
			 * @property {object} [lazyData={}] - provide data for lazy-loaded component
			 */
			lazyData: {
				type: Object,
				required: false,
				default: () => ({})
			},

			/**
			 * property {Function} [onClose=noop] - will be called once popup is closed
			 * @namespace Core_Blueprint_Popup
			 * @property {Function} [onClose=noop] - will be called once popup is closed
			 */
			onClose: {
				type: Function,
				required: false,
				default: function () {}
			},

			/**
			 * property {Function} [onOpen=noop] - will be called once popup is opened
			 * @namespace Core_Blueprint_Popup
			 * @property {Function} [onOpen=noop] - will be called once popup is opened
			 */
			onOpen: {
				type: Function,
				required: false,
				default: function () {}
			}
		},

		//  ---------- SETUP ----------
		setup(props) {
			// reactive vars
			const showPopup = Core.Vue.ref(false);
			const showPopupAnimation = Core.Vue.ref(false);
			const useComponent = Core.Vue.shallowRef();

			// it will remove the no-scroll when popup was shown and not closed properly (router path change)
			Core.Vue.onUnmounted(() => {
				document.body.classList.remove('noScroll');
			});

			/**
			 * toggle() popup on/off as required (the visibility state is controlled by the popup itself)
			 * @namespace Core_Blueprint_Popup
			 * @param {boolean} setTo force toggle to do TRUE, no matter what is set now
			 * @returns {boolean} true
			 */
			function toggle(setTo) {
				if (props.noClose && showPopup.value) {
					return false;
				}

				useComponent.value = props.lazyComponent;

				if (!showPopup.value) {
					// opening the popup
					showPopup.value = setTo || true;
					setTimeout(function () {
						showPopupAnimation.value = setTo || true;
					}, 100);

					props.onOpen() && function () {}; // call function on open
					document.body.classList.add('noScroll');
				} else {
					// closing the popup
					showPopupAnimation.value = setTo || false;
					setTimeout(function () {
						showPopup.value = setTo || false;
					}, 300);

					document.body.classList.remove('noScroll');
					setTimeout(() => {
						props.onClose();
					}, 300);
				}

				return true;
			}

			// ---------- EXPOSE ----------
			return { showPopup, toggle, useComponent, showPopupAnimation };
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('BpPopup') {
		background-color: transparentize($color-grey900, 0.2);
		bottom: 0;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		opacity: 1;
		z-index: var(--zindex-popups);

		&.is-hidden {
			transition: opacity 0.3s ease;
			opacity: 0;
		}

		@include element('container') {
			padding-left: var(--space-single) !important;
			padding-right: var(--space-single) !important;
			height: 100%;
		}

		@include element('header') {
			padding-top: var(--space-single);
		}

		@include element('row') {
			height: 100%;
		}

		@include element('title') {
			font-size: var(--text-h3);
			font-weight: var(--weight-bold);
			margin-top: -10px;
			padding-right: var(--space-double);
		}

		@include element('box') {
			background-color: var(--color-greyStart) !important;
			margin-bottom: 0 !important;
			max-height: calc(100vh - 30px);
			position: relative;
		}

		@include element('main') {
			overflow: auto;
			max-height: 60vh;
			scroll-behavior: smooth;
		}

		@include element('close') {
			cursor: pointer;
			position: absolute;
			right: 5px;
			top: 10px;
			padding: 10px;
			color: var(--color-grey500);

			&:hover {
				color: var(--color-primary);
			}

			& .iconFont-cross {
				font-size: 30px;
			}

			& svg {
				fill: var(--color-grey500);
				height: 30px;
				width: 30px;
			}
		}

		@include element('hr') {
			border: 0;
			border-bottom: 2px solid var(--color-tertiary);
			margin-left: -30px;
			width: calc(100% + 60px);
		}
	}
</style>
